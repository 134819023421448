import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getOrder } from '../api/order';
import ClipLoader from 'react-spinners/ClipLoader';

const calculateInitialTimeLeft = (createdAt) => {
    const createdAtDate = new Date(createdAt + 'Z'); // Parse as UTC
    const endTime = new Date(createdAtDate.getTime() + 20 * 60 * 1000); // Add 20 minutes
    const currentTime = new Date(); // Local time

    const timeLeft = endTime - currentTime;
    return Math.max(0, Math.floor(timeLeft / 1000));
};

const OrderRedirect = () => {
    const { orderId } = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchOrderDetail = async () => {
            try {
                const orderData = await getOrder(orderId);
                const initialTimeLeft = calculateInitialTimeLeft(orderData.createdAt);

                switch (orderData.status) {
                    case 'PENDING':
                        if (initialTimeLeft > 0) {
                            navigate(`/order/${orderId}/in-progress`);
                        } 
                        else {
                            navigate('/');
                        }
                        break;
                    case 'CONFIRMED':
                        if (orderData.saleroom === 'Không có dữ liệu') {
                            navigate(`/order/${orderId}/make-appointment`);
                        } else {
                            navigate(`/order/${orderId}/success`);
                        }
                        break;
                    case 'COMPLETED':
                        navigate(`/order/${orderId}/success`);
                        break;
                    default:
                        navigate('/404'); // Redirect to a 404 page if status is unknown
                }
            } catch (error) {
                console.error('Error fetching order status:', error);
                navigate('/404'); // Redirect to a 404 page on error
            } finally {
                setIsLoading(false);
            }
        };

        fetchOrderDetail();
    }, [orderId, navigate]);

    if (isLoading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <ClipLoader color="#000000" loading={isLoading} size={50} />
            </div>
        );
    }

    return null;
};

export default OrderRedirect;
