import React, { useState } from 'react';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="bg-white shadow-md p-6">
      <div className="container mx-auto flex justify-between items-center">
        <div className="flex items-center">
          <img src={`${process.env.PUBLIC_URL}/logo192.png`} alt="Logo" className="h-8 w-8 mr-2" />
          <span className="text-xl font-bold">GPI</span>
        </div>
        <nav className="hidden md:flex space-x-4">
          <a href="#trading" className="text-gray-700 hover:text-customYellow">Giao dịch</a>
          <a href="#market-analysis" className="text-gray-700 hover:text-customYellow">Phân tích thị trường</a>
          <a href="#news" className="text-gray-700 hover:text-customYellow">Tin tức</a>
          <a href="#about-us" className="text-gray-700 hover:text-customYellow">Về chúng tôi</a>
        </nav>
        <div className="hidden md:flex space-x-4">
          {/* <button className="bg-customBlue text-white px-4 py-2 rounded">Đăng nhập</button> */}
          {/* <button className="bg-customBlue100 text-black px-4 py-2 rounded">Đăng ký</button> */}
        </div>
        <div className="md:hidden">
          <button onClick={toggleMenu} className="text-gray-700 focus:outline-none">
            <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
          </button>
        </div>
      </div>
      {isOpen && (
        <div className="md:hidden">
          <nav className="flex flex-col space-y-2 p-4">
            <a href="#trading" className="text-gray-700 hover:text-blue-500">Giao dịch</a>
            <a href="#market-analysis" className="text-gray-700 hover:text-blue-500">Phân tích thị trường</a>
            <a href="#news" className="text-gray-700 hover:text-blue-500">Tin tức</a>
            <a href="#about-us" className="text-gray-700 hover:text-blue-500">Về chúng tôi</a>
          </nav>
          <div className="flex flex-col space-y-2 p-4">
            <button className="bg-blue-500 text-white px-4 py-2 rounded">Đăng nhập</button>
            <button className="bg-gray-500 text-white px-4 py-2 rounded">Đăng ký</button>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
