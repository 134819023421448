import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-customBlue text-white py-4">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-start md:items-center px-6 lg:px-40">
        <div className="flex flex-col items-center w-1/6 md:items-start mb-4 md:mb-0">
          <img src={`${process.env.PUBLIC_URL}/logo192.png`} alt="Uncle Gold Logo" className="w-16 h-16 mb-4" />
        </div>
        <div className="flex flex-col md:flex-row flex-grow justify-between text-sm">
          <div className="mb-8 md:mb-0 md:mr-8">
            <h3 className="text-lg font-bold text-customYellow mb-4">Hỗ trợ khách hàng</h3>
            <ul>
              <li className="mb-2"><a href="#contact" className="hover:underline">Liên hệ</a></li>
              <li className="mb-2"><a href="#guide" className="hover:underline">Hướng dẫn mua hàng</a></li>
              <li className="mb-2"><a href="#payment" className="hover:underline">Hình thức thanh toán</a></li>
              <li className="mb-2"><a href="#shipping" className="hover:underline">Chính sách giao hàng</a></li>
            </ul>
          </div>
          <div className="mb-8 md:mb-0 md:mr-8">
            <h3 className="text-lg font-bold text-customYellow mb-4">Chính sách pháp lý</h3>
            <ul>
              <li className="mb-2"><a href="#terms" className="hover:underline">Điều khoản sử dụng</a></li>
              <li className="mb-2"><a href="#privacy" className="hover:underline">Chính sách bảo mật</a></li>
              <li className="mb-2"><a href="#cookies" className="hover:underline">Chính sách cookie</a></li>
            </ul>
          </div>
          <div className="mb-8 md:mb-0">
            <h3 className="text-lg font-bold text-customYellow mb-4">Kết nối với chúng tôi</h3>
            <div className="flex flex-col items-center md:items-start">
              <a href="https://www.facebook.com" className="mr-4 mb-4"><img src={`${process.env.PUBLIC_URL}/facebook-icon.png`} alt="Facebook" className="w-6 h-6" /></a>
              <a href="https://www.dangkykinhdoanh.gov.vn" className="mr-4"><img src={`${process.env.PUBLIC_URL}/registered-icon.png`} alt="Đã đăng ký Bộ Công Thương" className="w-16 h-6" /></a>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto mt-8 pt-8 px-6 lg:px-40">
        <div className="border-t border-gray-400 w-full mx-auto"></div>
        <div className="text-sm mt-4">
          <p className="text-l font-bold text-customYellow mb-2">CÔNG TY CỔ PHẦN GPI</p>
          <p className="mb-2">Địa chỉ: Tòa nhà XYZ, số 245 Văn Cao, Thanh Xuân, Hà Nội</p>
          <p className="mb-2">Số điện thoại: (+84) 234 5678</p>
          <p className="mb-2">Email: contact@unclegold.vn</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
