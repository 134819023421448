import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getLatestTradeSession } from '../api/tradeSession';
import { createOrder } from '../api/order';
import InProgressModal from '../components/InProgressModal';

const fetchingTradeSessions = async () => {
    return getLatestTradeSession();
};

const startOrder = async (orderData) => {
    console.log(orderData);
    return createOrder(orderData);
};

const Home = () => {
    const [formValues, setFormValues] = useState({
        goldType: '',
        quantity: '',
        email: '',
        phoneNumber: ''
    });
    const [tradingSession, setTradingSession] = useState(null);
    const navigate = useNavigate();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // Loading state for startOrder

    useEffect(() => {
        const fetchTradingSessions = async () => {
            try {
                const data = await fetchingTradeSessions();
                setTradingSession(data);
            } catch (error) {
                console.error('Error fetching trading sessions:', error);
            }
        };

        fetchTradingSessions();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleClick = async (orderType) => {
        setIsSubmitted(true);

        if (!formValues.goldType || !formValues.quantity || !formValues.email || !formValues.phoneNumber) {
            alert('Vui lòng điền đầy đủ thông tin trước khi tạo giao dịch mới!');
            return;
        }

        if (!isValidEmail(formValues.email)) {
            alert('Email không đúng. Vui lòng điền lại!');
            return;
        }
        const orderTypeText = orderType === 'buy' ? 'Mua' : 'Bán';
        console.log(`Button clicked: ${orderType}`);

        setIsLoading(true); // Set loading state to true

        try {
            const responseData = await startOrder({
                trading_session_id: tradingSession.trading_session_id,
                data: { ...formValues, orderType: orderType }
            });
            const { order_id } = responseData;
            navigate(`/order/${order_id}/in-progress`, {
                state: { ...formValues, orderType: orderTypeText, tradingSessionId: tradingSession.trading_session_id }
            });
        } catch (error) {
            console.error('Error creating order:', error);
            alert('Không tạo được order. Vui lòng thử lại!');
        } finally {
            setIsLoading(false); // Set loading state to false
        }
    };

    const getCurrentDate = () => {
        const date = new Date();
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    return (
        <main className="container mx-auto w-full px-4 md:px-10 lg:px-40 py-12 mb-12">
            <div className="bg-white w-full max-w-screen-xl mx-auto py-12 flex justify-center items-center">
                <img src={`${process.env.PUBLIC_URL}/banner.png`} alt="Banner" className="w-full md:w-2/3" />
            </div>
            <div className="flex flex-col md:flex-row justify-between">
                <div className="w-full md:w-1/2 mb-8 md:mb-0 flex flex-col">
                    <h2 className="text-center text-customYellow text-xl font-bold font-montserrat mb-4">Giá vàng ngày: {getCurrentDate()}</h2>
                    <p className="text-center text-l text-gray-600 font-montserrat mb-8">Đơn vị tính: VND/lượng</p>
                    <table className="w-full border-collapse">
                        <thead className="bg-customBlue100">
                            <tr>
                                <th className="font-montserrat border-b border-customBlue p-1">Loại vàng</th>
                                <th className="font-montserrat border-b border-customBlue p-1">Giá mua</th>
                                <th className="font-montserrat border-b border-customBlue p-1">Giá bán</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tradingSession && tradingSession.price_data.map((item, index) => (
                                <tr key={index}>
                                    <td className="font-montserrat border-b border-customGray400 p-1 text-center">{item.type}</td>
                                    <td className="font-montserrat border-b border-customGray400 p-1 text-center">{item.buyPrice}</td>
                                    <td className="font-montserrat border-b border-customGray400 p-1 text-center">{item.sellPrice}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="w-full md:w-1/2 md:ml-8">
                    <h3 className="text-center text-3xl font-bold font-montserrat text-customBlack mb-4">Giao dịch</h3>
                    <form>
                        <div className="flex items-center mb-4">
                            <label className="w-1/3 text-sm font-bold font-montserrat whitespace-nowrap">Loại vàng:</label>
                            <select
                                name="goldType"
                                className={`w-2/3 p-1 border rounded ${isSubmitted && !formValues.goldType ? 'border-red-500' : 'border-gray-300'}`}
                                value={formValues.goldType}
                                onChange={handleInputChange}
                            >
                                <option value="">Chọn loại vàng</option>
                                {tradingSession && tradingSession.price_data.map((item, index) =>
                                    <option key={index} value={item.type}>{item.type}</option>
                                )}
                            </select>
                        </div>
                        <div className="flex items-center mb-4">
                            <label className="w-1/3 text-sm font-bold font-montserrat whitespace-nowrap">Số lượng (chỉ vàng):</label>
                            <input
                                type="number"
                                name="quantity"
                                className={`w-2/3 p-1 border rounded ${isSubmitted && !formValues.quantity ? 'border-red-500' : 'border-gray-300'}`}
                                value={formValues.quantity}
                                onChange={handleInputChange}
                            />
                        </div>
                        <h3 className="text-center text-xl font-bold font-montserrat mb-4">Thông tin liên lạc</h3>
                        <div className="flex items-center mb-4">
                            <label className="w-1/3 text-sm font-bold font-montserrat whitespace-nowrap flex items-center">
                                Email<span className="text-red-500 ml-1">*</span>
                            </label>
                            <input
                                type="email"
                                name="email"
                                className={`w-2/3 p-1 border rounded ${isSubmitted && (!formValues.email || !isValidEmail(formValues.email)) ? 'border-red-500' : 'border-gray-300'}`}
                                value={formValues.email}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="flex items-center mb-4">
                            <label className="w-1/3 text-sm font-bold font-montserrat whitespace-nowrap flex items-center">
                                Số điện thoại<span className="text-red-500 ml-1">*</span>
                            </label>
                            <input
                                type="tel"
                                name="phoneNumber"
                                className={`w-2/3 p-1 border rounded ${isSubmitted && !formValues.phoneNumber ? 'border-red-500' : 'border-gray-300'}`}
                                value={formValues.phoneNumber}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="flex justify-center gap-6">
                            <button
                                type="button"
                                className="bg-customBlue100 text-black font-montserrat px-6 py-2 rounded flex items-center justify-center"
                                onClick={() => handleClick('buy')}
                                disabled={isLoading}
                            >
                                MUA
                            </button>
                            <button
                                type="button"
                                className="bg-customBlue text-white px-6 py-2 rounded flex items-center justify-center"
                                onClick={() => handleClick('sell')}
                                disabled={isLoading}
                            >
                                BÁN
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <InProgressModal isOpen={isLoading} />
        </main>
    );
};

export default Home;
