import React from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root'); // Ensure the app root element is set for accessibility

const ConfirmationModal = ({ isOpen, onRequestClose, onConfirm }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50"
      overlayClassName="fixed inset-0 bg-black bg-opacity-30"
    >
      <div className="bg-white rounded-lg p-8">
        <h2 className="text-2xl font-bold mb-4">Xác nhận</h2>
        <p className="mb-4">Bạn có chắc chắn muốn hủy giao dịch này không?</p>
        <div className="flex justify-end space-x-4">
          <button onClick={onRequestClose} className="bg-gray-300 px-4 py-2 rounded">Tiếp tục</button>
          <button onClick={onConfirm} className="bg-red-500 text-white px-4 py-2 rounded">Hủy giao dịch</button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
