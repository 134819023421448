import { useLocation, useNavigate, useParams } from 'react-router-dom';
import QRCode from 'qrcode.react';
import { useEffect, useState } from 'react';
import { getOrder } from '../api/order';


// const baseUrl = process.env.REACT_APP_BASE_URL || 'http://localhost:3000';

const getFullUrl = (path) => {
    // Get the current host
    const host = window.location.host;

    // Get the protocol (http or https)
    const protocol = window.location.protocol;

    // Construct the full URL
    const fullUrl = `${protocol}//${host}${path}`;

    return fullUrl;
};


const fetchOrderDetails = async (orderId) => {
    return getOrder(orderId);
};

const SuccessTransaction = () => {
    const { orderId } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [orderDetails, setOrderDetails] = useState(location.state || null);
    const [isLoading, setIsLoading] = useState(!location.state);

    useEffect(() => {
        const fetchData = async () => {
            if (!location.state) {
                const data = await fetchOrderDetails(orderId);
                setOrderDetails(data);
                setIsLoading(false);
            }
        };

        fetchData();
    }, [location.state, orderId]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!orderDetails) {
        alert('No order details found. Redirecting to home page.');
        navigate('/');
        return null; // Do not render the component if orderDetails is null
    }

    const { goldType, quantity, email, phoneNumber, orderType, saleroom, timePeriod } = orderDetails;

    const handleClick = () => {
        navigate('/');
    };

    const url = getFullUrl(`/order/${orderId}`);

    return (
        <div className='relative w-full min-h-screen'>
            <img
                src={`${process.env.PUBLIC_URL}/background.jfif`}
                alt="Background"
                className="absolute inset-0 w-full h-full object-cover z-0"
            />
            <main className="container relative mx-auto px-8 md:px-8 py-12 flex flex-col items-center justify-center min-h-screen z-10">
                <div className="w-full max-w-2xl bg-customWhite bg-opacity-70 shadow-md p-6 md:p-10 mb-8 md:w-1/3 lg:w-1/2">
                    <div className="flex flex-col md:flex-row justify-center items-center mb-4">
                        <h1 className="text-2xl text-center font-bold flex-grow mb-4 md:mb-0">Giao dịch số: {orderId}</h1>
                    </div>
                    <div className="flex flex-col md:flex-row justify-center mb-8 w-full">
                        <div className="w-full md:w-full mb-4 md:mb-0"> 
                            <div className='flex flex-row justify-center items-center mb-3'>
                                <img src={`${process.env.PUBLIC_URL}/check.png`} alt="Check Icon" className="w-10 h-10 mx-2" />
                                <div>
                                    <p className="text-start font-montserrat text-xl font-bold">Giao dịch được tạo thành công!</p>
                                </div>
                            </div>
                            <div className="flex items-center mb-2">
                                <h2 className="w-full md:w-1/2 text-l font-bold mr-2">Loại giao dịch:</h2>
                                <p>{orderType}</p>
                            </div>
                            <div className="flex items-center mb-2">
                                <h2 className="w-full md:w-1/2 text-l font-bold mr-2">Loại vàng:</h2>
                                <p>{goldType}</p>
                            </div>
                            <div className="flex items-center mb-2">
                                <h2 className="w-full md:w-1/2 text-l font-bold mr-2">Số lượng:</h2>
                                <p>{quantity} chỉ</p>
                            </div>
                            <div className="flex items-center mb-2">
                                <h2 className="w-full md:w-1/2 text-l font-bold mr-2">Địa điểm giao dịch:</h2>
                                <p>{saleroom}</p>
                            </div>
                            <div className="flex items-center mb-2">
                                <h2 className="w-full md:w-1/2 text-l font-bold mr-2">Thời gian:</h2>
                                <p>{timePeriod}</p>
                            </div>
                            <h2 className="text-lg text-center font-bold mb-2">Thông tin liên lạc</h2>
                            <div className="flex items-center mb-2">
                                <h2 className="w-full md:w-1/2 text-l font-bold mr-2">Email:</h2>
                                <p>{email}</p>
                            </div>
                            <div className="flex items-center mb-2">
                                <h2 className="w-full md:w-1/2 text-l font-bold mr-2">Số điện thoại:</h2>
                                <p>{phoneNumber}</p>
                            </div>
                            <div className="flex justify-center mt-8">
                                <QRCode value={url} />
                            </div>
                            <div className="text-center font-bold mt-4">
                                <p>Vui lòng quẹt mã QR để lấy thông tin giao dịch.</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row justify-center items-center mb-4">
                        <button onClick={handleClick} className="bg-customBlue text-white px-3 py-2 rounded">Trang chủ</button>
                    </div>
                </div>
            </main>
        </div>
    );    
};

export default SuccessTransaction;
