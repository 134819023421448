// baseUrl from env
const baseUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

// base fetch function with url, method, optional body
export const baseFetch = (url, method, body) => {
    return fetch(`${baseUrl}/api/${url}`, {
        method,
        headers: {
        'Content-Type': 'application/json',
        },
        body: body ? JSON.stringify(body) : undefined,
    })
        .then(response => response.json());
}