import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import InProgressTransaction from './pages/InProgressTransaction';
import MakeAppointment from './pages/MakeAppointment';
import SuccessTransaction from './pages/SuccessTransaction';
import OrderRedirect from './components/OrderRedirect';
import NotFound from './pages/NotFound';
import Header from './components/Header';
import Footer from './components/Footer';

const AppContent = () => {
  const location = useLocation();
  const hideHeaderFooter = location.pathname === '/404'; // Add any other paths you want to exclude here

  return (
    <div className="flex flex-col min-h-screen">
      {!hideHeaderFooter && <Header />}
      <main className="flex-grow container mx-auto">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/order/:orderId" element={<OrderRedirect />} />
          <Route path="/order/:orderId/in-progress" element={<InProgressTransaction />} />
          <Route path="/order/:orderId/make-appointment" element={<MakeAppointment />} />
          <Route path="/order/:orderId/success" element={<SuccessTransaction />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </main>
      {!hideHeaderFooter && <Footer />}
    </div>
  );
};

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
