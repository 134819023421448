import { baseFetch } from './base.js';


const basePath = 'order';

export const createOrder = (body) => {
  return baseFetch(`${basePath}`, 'POST', body);
}

export const getOrder = (orderId) => {
  return baseFetch(`${basePath}/${orderId}`, 'GET');
}

export const makeAppointment = (orderId, body) => {
  return baseFetch(`${basePath}/${orderId}/appointment`, 'PUT', body);
}