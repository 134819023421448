import React from 'react';

const InProgressModal = ({ isOpen }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
            <div className="bg-white p-4 rounded shadow-md">
                <h2 className="text-xl font-bold mb-2">Processing...</h2>
                <p>Please wait while your order is being processed.</p>
            </div>
        </div>
    );
};

export default InProgressModal;