import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import { getSalerooms } from '../api/saleroom';
import { makeAppointment, getOrder } from '../api/order';

const fetchSessionAppointments = async (sessionId) => {
    return getSalerooms(sessionId);
};

const MakeAppointment = () => {
    const { orderId } = useParams();
    const [orderData, setOrderData] = useState(null);
    const [salerooms, setSalerooms] = useState([]);
    const [timePeriods, setTimePeriods] = useState([]);
    const [saleroom, setSaleroom] = useState('');
    const [timePeriod, setTimePeriod] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(true); // Loading state for fetching data
    const [isSubmitting, setIsSubmitting] = useState(false); // Loading state for form submission
    // const formValues = location.state || {};
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const orderDetails = await getOrder(orderId);
                setOrderData(orderDetails);
                const data = await fetchSessionAppointments(orderDetails.tradingSessionId);
                setSalerooms(data.salerooms);
                setTimePeriods(data.timePeriods);
            } catch (error) {
                console.error('Error fetching session appointments:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [orderId]);

    const handleSaleroomChange = (e) => {
        setSaleroom(e.target.value);
    };

    const handleTimePeriodChange = (e) => {
        setTimePeriod(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitted(true);
        if (!saleroom || !timePeriod) {
            alert('Vui lòng chọn địa điểm và thời gian.');
            return;
        }
        setIsSubmitting(true); // Set loading state to true

        const appointmentData = {
            saleroom: parseInt(saleroom, 10),
            timePeriod: parseInt(timePeriod, 10),
        };

        try {
            const response = await makeAppointment(orderId, appointmentData);
            if (response.order_id) {
                navigate(`/order/${orderId}`);
                return;
            }

            alert('Có lỗi xảy ra. Vui lòng thử lại sau.');
        } catch (error) {
            console.error('Error making appointment:', error);
            alert('Có lỗi xảy ra. Vui lòng thử lại sau.');
        } finally {
            setIsSubmitting(false); // Set loading state to false
        }

        console.log(`Location: ${saleroom}, Time: ${timePeriod}`);
    };

    if (isLoading || !orderData) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <ClipLoader color="#3498db" loading={isLoading} size={50} />
            </div>
        );
    }

    return (
        <div className="relative w-full min-h-screen overflow-hidden">
            <img
                src={`${process.env.PUBLIC_URL}/background.jfif`}
                alt="Background"
                className="absolute top-0 left-0 w-full h-full object-cover"
            />
            <main className="container relative mx-auto px-8 md:px-8 py-12 flex flex-col items-center justify-center min-h-screen">
                <div className="bg-customWhite bg-opacity-70 shadow-md p-4 mb-8 w-full md:w-2/3 lg:w-1/2">
                    <h1 className="text-2xl font-bold text-center mb-4">Mã giao dịch: {orderId}</h1>
                    <div className='flex flex-row justify-center items-center'>
                        <img src={`${process.env.PUBLIC_URL}/check.png`} alt="Check Icon" className="w-10 h-10 mx-2" />
                        <div className=''>
                            <p className="text-start font-montserrat font-bold">Chuyển tiền cọc thành công!</p>
                            <p className="text-start font-montserrat mb-4">Số tiền: {orderData.depositAmount}</p>
                        </div>
                    </div>
                    <p className="text-center font-montserrat font-bold mb-8">Quý khách vui lòng chọn thời gian và địa điểm giao dịch bên dưới</p>
                    {isLoading ? (
                        <div className="flex justify-center items-center">
                            <ClipLoader color="#3498db" loading={isLoading} size={50} />
                        </div>
                    ) : (
                        <form onSubmit={handleSubmit}>
                            <div className="flex flex-row mb-4">
                                <label className="block text-gray-700 mb-2 md:w-1/4">Địa điểm</label>
                                <select
                                    className={`bg-customWhite bg-opacity-50 mb-2 w-full border rounded ${isSubmitted && !saleroom ? 'border-red-500' : 'border-gray-300'}`}
                                    value={saleroom}
                                    onChange={handleSaleroomChange}
                                >
                                    <option value="">Chọn địa điểm</option>
                                    {salerooms && salerooms.map((item, index) => <option key={index} value={item.id}>{item.address}</option>)}
                                </select>
                            </div>
                            <div className="flex flex-row mb-4">
                                <label className="block text-gray-700 mb-2 md:w-1/4">Thời gian</label>
                                <select
                                    className={`bg-customWhite bg-opacity-50 mb-2 w-full border rounded ${isSubmitted && !timePeriod ? 'border-red-500' : 'border-gray-300'}`}
                                    value={timePeriod}
                                    onChange={handleTimePeriodChange}
                                >
                                    <option value="">Chọn thời gian</option>
                                    {timePeriods && timePeriods.map((item, index) => <option key={index} value={item.id}>{item.content}</option>)}
                                </select>
                            </div>
                            <div className="text-center">
                                <button type="submit" className="bg-customBlue text-white px-4 py-2 rounded" disabled={isSubmitting}>
                                    {isSubmitting ? <ClipLoader color="#ffffff" loading={isSubmitting} size={20} /> : 'Đồng ý'}
                                </button>
                            </div>
                        </form>
                    )}
                </div>
            </main>
        </div>
    );    
};

export default MakeAppointment;
