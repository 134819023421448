// components/NotFound.js
import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-blue-500 to-teal-500">
            <div className="bg-white p-8 rounded-lg shadow-lg text-center max-w-md">
                <h1 className="text-6xl font-bold text-blue-600 mb-4">404</h1>
                <p className="text-2xl text-gray-700 mb-8">Oops! Page Not Found</p>
                <Link to="/" className="text-white bg-blue-600 px-4 py-2 rounded-lg hover:bg-blue-700 transition duration-300">
                    Go back to Home
                </Link>
            </div>
        </div>
    );
};

export default NotFound;
