import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ConfirmationModal from '../ConfirmationModal';
import { getOrder } from '../api/order';
import { ClipLoader } from 'react-spinners';

const fetchOrderDetails = async (orderId) => {
    return getOrder(orderId);
};

const calculateInitialTimeLeft = (createdAt) => {
    const createdAtDate = new Date(createdAt + 'Z'); // Parse as UTC
    const endTime = new Date(createdAtDate.getTime() + 20 * 60 * 1000); // Add 20 minutes
    const currentTime = new Date(); // Local time

    const timeLeft = endTime - currentTime;
    return Math.max(0, Math.floor(timeLeft / 1000));
};

const InProgressTransaction = () => {
    const { orderId } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const alertShownRef = useRef(false);
    const countdownIntervalRef = useRef(null);
    const fetchIntervalRef = useRef(null);

    const [orderData, setOrderData] = useState(null);
    const [timeLeft, setTimeLeft] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isFetching, setIsFetching] = useState(true);

    useEffect(() => {
        const fetchDetails = async () => {
            try {
                const data = await fetchOrderDetails(orderId);
                setOrderData(data);

                const initialTimeLeft = calculateInitialTimeLeft(data.createdAt);
                setTimeLeft(initialTimeLeft);

                if (initialTimeLeft <= 0 && data.status !== 'CONFIRMED') {
                    alert('Thời gian chờ chuyển cọc đã hết. Giao dịch đã bị hủy. Quý khách vui lòng tạo giao dịch khác!');
                    navigate('/');
                    return;
                }
            } catch (error) {
                console.error('Error fetching order details:', error);
                navigate('/');
            } finally {
                setIsFetching(false);
            }
        };

        fetchDetails();
    }, [orderId, navigate]);

    useEffect(() => {
        if (!isFetching) {
            countdownIntervalRef.current = setInterval(() => {
                setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
            }, 1000);

            fetchIntervalRef.current = setInterval(async () => {
                try {
                    const data = await fetchOrderDetails(orderId);
                    setOrderData(data);
                    if (data.status === 'CONFIRMED') {
                        clearInterval(countdownIntervalRef.current);
                        clearInterval(fetchIntervalRef.current);
                        navigate(`/order/${orderId}/make-appointment`, {
                            state: { ...location.state },
                        });
                    }
                } catch (error) {
                    console.error('Error fetching order details:', error);
                }
            }, 10000);
        }

        return () => {
            clearInterval(countdownIntervalRef.current);
            clearInterval(fetchIntervalRef.current);
        };
    }, [isFetching, orderId, navigate, location.state]);

    useEffect(() => {
        if (timeLeft === 0 && orderData && orderData.status !== 'CONFIRMED' && !alertShownRef.current) {
            alertShownRef.current = true;
            alert('Thời gian chờ chuyển cọc đã hết. Giao dịch đã bị hủy. Quý khách vui lòng tạo giao dịch khác!');
            navigate('/');
        }
    }, [timeLeft, orderData, navigate]);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    };

    const handleCancelTransaction = () => {
        setIsModalOpen(true);
    };

    const handleConfirmCancel = () => {
        setIsModalOpen(false);
        navigate('/');
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    if (!orderData) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <ClipLoader color="#3498db" loading={isFetching} size={50} />
            </div>
        );
    }

    return (
        <div className="relative w-full">
            <img
                src={`${process.env.PUBLIC_URL}/background.jfif`}
                alt="Background"
                className="absolute inset-0 w-full h-full bg-cover"
            />
            <div className="relative w-full">
                <main className="container mx-auto px-4 md:px-8 py-8 flex flex-col items-center justify-center min-h-screen">
                    <div className="bg-customWhite bg-opacity-70 shadow-md p-4 mb-8 w-full md:w-3/4 lg:w-2/3">
                        <div className="flex flex-col md:flex-row justify-between items-center mb-4">
                            <h1 className="text-2xl text-center font-bold font-montserrat flex-grow mb-4 md:mb-0">Giao dịch số: {orderId}</h1>
                        </div>
                        <div className="flex flex-col md:flex-row justify-between mb-8">
                            <div className="w-full md:w-1/2 mb-4 md:mb-0">
                                <h2 className="text-lg text-center font-bold font-montserrat mb-2">Thông tin giao dịch</h2>
                                <div className="flex items-center mb-2">
                                    <h2 className="w-1/2 md:w-1/3 text-l font-bold font-montserrat mr-2">Loại giao dịch:</h2>
                                    <p>{orderData.orderType}</p>
                                </div>
                                <div className="flex items-center mb-2">
                                    <h2 className="w-1/2 md:w-1/3 text-l font-bold font-montserrat mr-2">Loại vàng:</h2>
                                    <p>{orderData.goldType}</p>
                                </div>
                                <div className="flex items-center mb-2">
                                    <h2 className="w-1/2 md:w-1/3 text-l font-bold font-montserrat mr-2">Số lượng:</h2>
                                    <p>{orderData.quantity} lượng</p>
                                </div>
                                <div className="flex items-center mb-2">
                                    <h2 className="w-1/2 md:w-1/3 text-l font-bold font-montserrat mr-2">Số tiền cọc cần chuyển:</h2>
                                    <p>{orderData.depositAmount}</p>
                                </div>
                                <div className="flex items-center mb-2">
                                    <h2 className="w-1/2 md:w-1/3 text-l font-bold font-montserrat mr-2">Tài khoản chuyển đến:</h2>
                                    <p>000213003<br />Ngân hàng TP bank</p>
                                </div>
                                <div className="flex items-center mb-2">
                                    <h2 className="w-1/2 md:w-1/3 text-l font-bold font-montserrat mr-2">Nội dung chuyển khoản:</h2>
                                    <p>coc {orderId}</p>
                                </div>
                            </div>
                            <div className="w-full md:w-1/2 mb-4 md:mb-0">
                                <h2 className="text-lg text-center font-bold font-montserrat mb-2">Thông tin liên lạc</h2>
                                <div className="flex items-center mb-2">
                                    <h2 className="w-1/2 md:w-1/3 text-l font-bold font-montserrat mr-2">Email:</h2>
                                    <p>{orderData.email}</p>
                                </div>
                                <div className="flex items-center mb-2">
                                    <h2 className="w-1/2 md:w-1/3 text-l font-bold font-montserrat mr-2">Số điện thoại:</h2>
                                    <p>{orderData.phoneNumber}</p>
                                </div>
                            </div>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div className="p-4 border border-gray-300 bg-customBlue50">
                                <h2 className="text-xl text-center font-bold font-montserrat mb-2">Trạng thái giao dịch</h2>
                                <p className="text-center font-montserrat">Chờ chuyển tiền cọc</p>
                            </div>
                            <div className="p-4 border border-gray-300 bg-customYellow2">
                                <h2 className="text-3xl text-center font-bold font-montserrat mb-2">Thời gian</h2>
                                <p className="text-center font-montserrat">Bạn có 20 phút để hoàn thành chuyển tiền cọc</p>
                                <p className="text-4xl text-center font-bold">{formatTime(timeLeft)}</p>
                            </div>
                        </div>
                        <div className="flex justify-center items-center mt-4">
                            <button onClick={handleCancelTransaction} className="bg-customBlue font-montserrat text-white px-4 py-2 rounded mx-auto">Hủy giao dịch</button>
                        </div>
                    </div>
                    <ConfirmationModal
                        isOpen={isModalOpen}
                        onRequestClose={handleCloseModal}
                        onConfirm={handleConfirmCancel}
                    />
                </main>
            </div>
        </div>
    );
    
};

export default InProgressTransaction;
